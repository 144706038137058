/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, StaticQuery, graphql } from 'gatsby'
import { useLocation } from "@reach/router";
import socialBanner from "../images/og-image.png";

function SEO({ description, lang, image, meta, title, url, ...props }) {
    const { pathname, host } = useLocation()

    const isCanonical = (host === 'upplan.sg' || host === 'www.upplan.sg') ? true : false

    return (
        <StaticQuery
        query={query}
        render={data => {
            const metaTitle = title || data.site.siteMetadata.title
            const metaDescription = description || data.site.siteMetadata.description
            const metaImage = image ? `${image}` : `${data.site.siteMetadata.siteUrl}${socialBanner}`
            const canonical = isCanonical ? `${data.site.siteMetadata.siteUrl}${pathname}` : false
            const metaUrl = url || data.site.siteMetadata.siteUrl

            return (
                <Helmet
                htmlAttributes={{
                  lang,
                }}
                title={metaTitle}
                titleTemplate={`Up | %s`}
                link={
                    canonical
                      ? [
                          {
                            rel: "canonical",
                            href: canonical,
                          },
                        ]
                      : []
                  }
                meta={[
                  {
                    name: `description`,
                    content: metaDescription,
                  },
                  {
                    property: `og:title`,
                    content: metaTitle,
                  },
                  {
                    property: `og:url`,
                    content: metaUrl,
                  },
                  {
                    property: `og:description`,
                    content: metaDescription,
                  },
                  {
                    property: `og:type`,
                    content: `website`,
                  },
                  {
                    name: `twitter:creator`,
                    content: `@${data.site.siteMetadata.social.twitter}`,
                  },
                  {
                    name: `twitter:title`,
                    content: metaTitle,
                  },
                  {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                  property: `og:image`,
                  content: metaImage
                },
                {
                  property: `og:image:alt`,
                  content: metaTitle,
                },
                {
                  name: `twitter:image`,
                  content: metaImage
                },
                {
                  name: `twitter:card`,
                  content: `summary_large_image`,
                }
                ]
                  .concat(metaImage ? [
                    {
                      property: `og:image`,
                      content: metaImage
                    },
                    {
                      property: `og:image:alt`,
                      content: metaTitle,
                    },
                    {
                      name: `twitter:image`,
                      content: metaImage
                    },
                    {
                      name: `twitter:card`,
                      content: `summary_large_image`,
                    }
                ] : null)
                  .concat(meta)}
                />
            )
        }}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    pathname: ``
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    image: PropTypes.string,
    meta: PropTypes.array,
    pathname: PropTypes.string,
    title: PropTypes.string.isRequired
}

export default SEO

const query = graphql`
  query SEO {
      site {
          siteMetadata {
              title
              siteUrl: siteUrl
              description
              defaultImage: image
              author
              social {
                  twitter
              }
          }
      }
  }
`

import React from "react";
import { Link } from "gatsby";
import Contact from "../Contact"
import style from "./Footer.module.css"

const Links = ({adviser}) =>
    <div className="footer-links uk-grid uk-margin-large uk-margin-large-bottom uk-flex-center" data-uk-grid>
        <div className="uk-width-2-3@m">
            <div className="uk-grid uk-grid-stack uk-child-width-1-3@s" data-uk-grid>
                <div>
                    <ul className="uk-list">
                        <li className="uk-h4 uk-text-bold uk-margin-remove-bottom">Discover</li>
                        <li><a href={`https://www.bettertradeoff.com`} target="_blank" rel="nofollow">bettertradeoff.com</a></li>
                        <li><Link to={`/blog`}>Blog</Link></li>
                        <li><a href={`https://www.bettertradeoff.com/in-the-news/`} target="_blank" rel="nofollow">In the News</a></li>
                    </ul>
                </div>
                <div>
                    <ul className="uk-list">
                        <li className="uk-h4 uk-text-bold uk-margin-remove-bottom">Legal</li>
                        <li><a href={`https://www.bettertradeoff.com/bto-terms-and-conditions-of-use`} target="_blank" rel="nofollow">Terms & Conditions</a></li>
                        <li><a href={`https://www.bettertradeoff.com/bto-privacy-and-data-protection-notice`} target="_blank" rel="nofollow">Privacy Policy</a></li>
                    </ul>
                </div>
                <div>
                    <ul className="uk-list">
                        <li className="uk-h4 uk-text-bold uk-margin-remove-bottom">Support</li>
                        <li><Link to={adviser ? `/up-adviser-get-help` : `/get-help`}>Get Help</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

export const Footer = ({adviser}) =>
    <section className={`${style.footerContent} footer uk-section uk-section-large uk-section-muted uk-padding-remove-bottom`}>
        <div className="uk-container">
            <h2 className="uk-text-center uk-margin-large bottom">Contact us</h2>
            <div className="uk-grid uk-grid-large uk-flex-center" data-uk-grid >
                <div className="uk-width-2-3@m">
                    <Contact adviser={adviser} />
                </div>
            </div>
            <Links adviser={adviser} />
        </div>
        <div className="uk-container uk-padding-remove-bottom uk-margin-remove-bottom">
            <div className="uk-text-center uk-padding uk-padding-remove-horizontal">
                <small className="uk-text-muted">&copy; 2018-2022 <a href="https://www.bettertradeoff.com">BetterTradeOff Solutions Pte. Ltd.</a> All Rights Reserved.</small>
            </div>
        </div>
    </section>

export const LandingFooter = ({adviser}) =>
    <section className={`${style.footerContent} footer uk-section uk-section-large uk-section-muted uk-padding-remove-bottom`}>
        <div className="uk-container">
            <Links adviser={adviser} />
        </div>
        <div className="uk-container uk-padding-remove-bottom uk-margin-remove-bottom">
            <div className="uk-text-center uk-padding uk-padding-remove-horizontal">
                <small className="uk-text-muted">&copy; 2018-2022 <a href="https://www.bettertradeoff.com">BetterTradeOff Solutions Pte. Ltd.</a> All Rights Reserved.</small>
            </div>
        </div>
    </section>

import React from "react";
import { useLocation } from "@reach/router";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import style from "./Navigation.module.css";
import defaultLogo from "../../images/up-logo.svg";
import adviserLogo from "../../images/up-adviser-logo.svg";

const newPath = ['up-adviser', 'up_adviser', 'upadviser', 'up-advisor', 'up_advisor', 'upadvisor'];

const OffCanvas = ({logo, adviser}) =>
    <div className={`uk-navbar-container uk-navbar-transparent uk-hidden@m`}
        data-uk-sticky="cls-active: uk-navbar-sticky uk-box-shadow-small;">
        <nav className={`${style.mobileNav}`} data-uk-navbar>
            <div className={`uk-navbar-left`}>
                <div className={`${style.Logo} uk-navbar-item uk-logo`}>
                    <Link to={adviser ? `/up-adviser` : `/`}><img src={logo} alt="Logo" /></Link>
                </div>
            </div>
            <div className="uk-navbar-right">
                <button
                    type="button"
                    data-uk-toggle="target: #mobileNav"
                    className="uk-button-text uk-navbar-toggle uk-navbar-item"
                    data-uk-navbar-toggle-icon />
            </div>
        </nav>
        <div id="mobileNav" data-uk-offcanvas="mode: push; flip: true">
            <div className="uk-offcanvas-bar uk-flex uk-flex-column">
                <button
                    className="uk-position-top-right uk-position-small uk-offcanvas-close"
                    type="button"
                    data-uk-close />
                <ul className="uk-nav uk-nav-primary uk-text-center uk-margin-auto-vertical uk-nav-parent-icon" data-uk-nav>
                    <li className="uk-margin-bottom"><Link to={`/blog`}>Blog</Link></li>
                    <li className="uk-margin-bottom"><a href={adviser ? `https://adviser.upplan.sg/account/login#!/login` : `https://app.upplan.sg/account/login`}>Login</a></li>
                    <li className="uk-margin-bottom"><a href={adviser ? `https://registration.upadviser.sg` : `https://account.upplan.sg/register-c/get-started`}>{adviser ? `Try It Free` : `Start Now`}</a></li>
                    <li className="uk-margin-bottom"><a href={adviser ? `/up-adviser-contact-us` : `/contact-us`}>Contact Us</a></li>
                    <li><a href={adviser ? `/up-adviser-get-help` : `/get-help`}>Support</a></li>
                </ul>
            </div>
        </div>
    </div>

const DesktopNav = ({adviser, logo}) =>
    <div className={`${style.nav}`} data-uk-sticky="cls-active: uk-navbar-sticky uk-box-shadow-small;">
        <div className="uk-container">
            <nav className="uk-navbar-container uk-navbar-transparent uk-visible@m" data-uk-navbar>
                <div className="uk-navbar-left">
                    <div className={`uk-navbar-item uk-logo ${style.Logo}`}>
                    <a href={adviser ? `/up-adviser` : `/`}><img src={logo} alt="Up Plan by BetterTradeOff" width="50" data-uk-img /></a>
                    </div>
                </div>
                <div className="uk-navbar-right">
                    <div className="uk-navbar-nav uk-margin-xlarge-right@m uk-visible@m">
                        <div className="uk-navbar-item">
                            <Link to={`/blog`} className="uk-button uk-link-primary uk-button-text uk-text-nowrap uk-margin-right">Blog</Link>
                        </div>
                        <div className="uk-navbar-item">
                            <a href={adviser ? `https://adviser.upplan.sg/account/login#!/login` : `https://app.upplan.sg/account/login`} className="uk-button uk-link-primary uk-button-text uk-text-nowrap uk-margin-right">Login</a>
                        </div>
                        <div className="uk-navbar-item">
                            <a href={adviser ? `https://registration.upadviser.sg` : `https://account.upplan.sg/register-c/get-started`} className="uk-button uk-button-blue uk-border-pill uk-text-nowrap">{adviser ? `Try It Free` : `Start Now`}</a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

export const Navigation = ({adviser}) => {
    const logo = adviser ? adviserLogo : defaultLogo;

    return (
        <>
        <DesktopNav adviser={adviser} logo={logo} />
        <div className="uk-hidden@m">
            <OffCanvas
                logo={logo}
                adviser={adviser} />
        </div>
        </>
    )
}

export const LandingNav = ({adviser, conversionUrl, ...props}) => {
    const logo = adviser ? adviserLogo : defaultLogo;

    return (
        <div className={`${style.nav}`} data-uk-sticky="cls-active: uk-navbar-sticky uk-box-shadow-small;">
            <div className="uk-container">
                <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                    <div className="uk-navbar-left">
                        <div className={`uk-navbar-item uk-logo ${style.Logo}`}>
                            <img src={logo} alt="Up Adviser by BetterTradeOff" width="50" data-uk-img />
                        </div>
                    </div>
                    <div className="uk-navbar-right">
                        <div className="uk-navbar-nav uk-margin-xlarge-right@m">
                            <div className="uk-navbar-item">
                                <a href={conversionUrl ? conversionUrl : "https://account.upplan.sg/register"} className={`${style.mobileButton} uk-button uk-button-blue uk-border-pill uk-text-nowrap`}>{adviser ? `Request Demo` : `Start Now`}</a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}
